import React, { useState } from "react";
import { Section } from "./Section";
import { RichText } from "./RichText";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Field from "./Field";

export const ArticleList = ({ articles, allArticles = [], tags }) => {
  const [foundArticles, setFoundArticles] = useState(articles);
  const itemsAmount = foundArticles.length;

  if (allArticles.length === 0) {
    allArticles = articles;
  }

  return (
    <Section width={11} className="bg-gray-100-gradient-50 pt-3rem pt-lg-5rem">
      <div className="col-12 col-md-8 pt-0rem pt-lg-5rem">
        {foundArticles.map((article, index) => (
          <ArticleTeaser
            itemsAmount={itemsAmount}
            key={index}
            article={article}
            index={index}
          />
        ))}
      </div>
      <div className="col-12 col-md-4 text-left text-md-right">
        <Search {...{ setFoundArticles, allArticles }} />
        {tags && <TagList {...tags} />}
      </div>
    </Section>
  );
};

const Search = ({ setFoundArticles, allArticles }) => {
  const [form, setForm] = useState({});
  const handleChange = ({ name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.search) {
      setFoundArticles(allArticles);

      return;
    }

    const filteredArticles = allArticles.filter((article) =>
      article.title.toLowerCase().includes(form.search.toLowerCase())
    );

    setFoundArticles(filteredArticles);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Field
        {...{
          placeholder: "Szukaj",
          name: "search",
          onChange: handleChange,
          color: "c-black",
          colorActive: "c-blue",
          borderColor: "bc-black",
          borderColorActive: "bc-blue",
        }}
      />
    </form>
  );
};

const TagList = ({ tags, title }) => (
  <>
    <div className="pt-3rem pt-md-5rem pb-2rem font-weight-bold fs-1_5">
      {title}
    </div>
    <ul className="list-unstyled">
      {tags.map(({ name, slug }) => (
        <li
          className="d-inline-block d-md-block pr-1rem pr-md-0 pb-1rem"
          key={name}
        >
          <AnchorLink to={`/tags/${slug}`} className="c-black">
            {name}
          </AnchorLink>
        </li>
      ))}
    </ul>
  </>
);

const ArticleTeaser = ({ article, itemsAmount, index }) => {
  return (
    <div>
      <AnchorLink
        to={`/blog/${article.slug}`}
        className="c-black font-weight-bold fs-2 d-flex align-items-center cursor-pointer text-uppercase text-decoration-none pb-2rem"
      >
        {article.title}
      </AnchorLink>
      <div className="col-12 p-0">
        <AnchorLink to={`/blog/${article.slug}`}>
          <img src={article.mainImage.file.url} alt="icon" className="w-100" />
        </AnchorLink>
      </div>
      <RichText className="pt-3rem pb-2rem" text={article.excerpt.raw} />
      <AnchorLink
        to={`/blog/${article.slug}`}
        className="pb-3rem font-weight-bold fs-1_5 d-flex align-items-center cursor-pointer text-uppercase c-black text-decoration-none"
      >
        <div className="faq__plus mr-1rem" />
        Czytaj więcej
      </AnchorLink>
      {itemsAmount > index + 1 && (
        <div
          className={`pt-3rem border-top bw-2 bc-blue w-100`}
          style={{ maxWidth: "432px" }}
        />
      )}
    </div>
  );
};
