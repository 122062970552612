import React from "react";
import { Section } from "./Section";
import PrimaryLinkButton from "./PrimaryLinkButton";

export const Buttons = ({
  buttons,
  spaceBottom,
  spaceTop,
  backgroundColor,
  alignButtons = "",
}) => (
  <Section
    width={11}
    className={`pb-${spaceBottom || 5}rem pt-${
      spaceTop || 5
    }rem ${backgroundColor}`}
  >
    <div className="col-12">
      <div
        className={`d-flex flex-wrap ${alignButtons}`}
        data-aos="fade-up"
        data-aos-delay="200"
      >
        {buttons.map((button, index) => (
          <PrimaryLinkButton button={button} className="mr-2rem" key={index} />
        ))}
      </div>
    </div>
  </Section>
);
