import React from "react";
import { Section } from "./Section";

export const SectionImage = ({
  image,
  backgroundColor,
  animationType,
  spaceBottom = 5,
  spaceTop = 5,
}) => (
  <Section
    width={11}
    className={`pb-3rem ${backgroundColor} pb-lg-${spaceBottom}rem pt-lg-${spaceTop}rem`}
  >
    <div className="col-12">
      <img
        src={image.file.url}
        alt="icon"
        className="w-100"
        data-aos={animationType}
      />
    </div>
  </Section>
);
