import React, { useState } from "react";
import { Section } from "./Section";
import { RichText } from "./RichText";
import Arrow from "./Arrow";

export const SectionFaq = ({ items }) => {
  const itemsAmount = items.length;
  return (
    <Section width={11} className="bg-gray-100-gradient-50">
      <div className="col-12">
        {items.map((item, index) => (
          <FaqItem
            itemsAmount={itemsAmount}
            key={index}
            item={item}
            index={index}
          />
        ))}
      </div>
      <div className="col-12 col-md-4 mt-3rem mt-lg-20rem">
        <div className={`py-3rem border-lg-top bw-lg-2 bc-blue`}>
          <div className="font-weight-bold fs-1_5 pb-1_5rem">
            Nie znalazłeś odpowiedzi na swoje pytanie?
          </div>
          <div className="font-weight-bold fs-2 pb-2rem">NAPISZ DO NAS!</div>
          <div>
            <Arrow />
          </div>
        </div>
      </div>
    </Section>
  );
};

const FaqItem = ({ index, itemsAmount, item }) => {
  const [textIsVisible, setIsVisible] = useState(false);
  const handleClick = () => setIsVisible(!textIsVisible);

  return (
    <div
      className={`py-3rem border-top bw-2 bc-blue ${
        index + 1 === itemsAmount && "border-bottom"
      }`}
    >
      <div
        className="font-weight-bold fs-1_25 fs-xxl-1_5 d-flex align-items-center cursor-pointer"
        onClick={handleClick}
      >
        <div className="faq__plus mr-1rem" />
        {item.title}
      </div>
      <RichText
        className={`pt-3rem pl-2_75rem pl-lg-7rem ${
          !textIsVisible && "d-none"
        }`}
        text={item.text.raw}
      />
    </div>
  );
};
