import "../scss/app.scss";
import React from "react";
import { graphql } from "gatsby";
import { Navbar } from "../component/Navbar";
import { Footer } from "../component/Footer";
import { FinePrints } from "../component/FinePrints";
import { Contact } from "../component/Contact";
import { SectionTextImage } from "../component/SectionTextImage";
import { SectionImage } from "../component/SectionImage";
import { Buttons } from "../component/Buttons";
import { Spacer } from "../component/Spacer";
import { SectionFaq } from "../component/SectionFaq";
import { Identifications } from "../component/Identifications";
import { ArticleList } from "../component/ArticleList";
import AppHelmet from "../component/AppHelmet";
import { usePageInit } from "../component/hook/usePageInit";
import { Cookies } from "../component/Cookies";

const components = {
  ContentfulNavbar: Navbar,
  ContentfulSectionTextImage: SectionTextImage,
  ContentfulContact: Contact,
  ContentfulFooter: Footer,
  ContentfulSectionImage: SectionImage,
  ContentfulSectionButtons: Buttons,
  ContentfulFinePrints: FinePrints,
  ContentfulSectionFaq: SectionFaq,
  ContentfulIdentifications: Identifications,
  ContentfulSpacer: Spacer,
  ContentfulArticlesList: ArticleList,
};

function Blog({ data }) {
  const { popup } = usePageInit();

  const {
    contentfulPage: { sections },
    allContentfulArticlePage: { nodes },
  } = data;

  return (
    <main>
      <AppHelmet title="Blog" />
      {popup}
      {sections.map(({ id, __typename, ...props }) => {
        const SectionTag = components[__typename];

        if (__typename === "ContentfulArticlesList") {
          props.articles = nodes;
        }

        return SectionTag && <SectionTag {...props} key={id} />;
      })}
      <Cookies />
    </main>
  );
}

export default Blog;

export const sectionOneQuery = graphql`
  query blogQuery {
    allContentfulArticlePage {
      nodes {
        id
        title
        slug
        excerpt {
          raw
        }
        mainImage {
          file {
            url
          }
        }
      }
    }
    contentfulPage(id: { eq: "8e77cdfb-4095-55e3-9759-15b494f29258" }) {
      title
      id
      sections {
        ... on ContentfulArticlesList {
          id
          __typename
          tags {
            title
            tags {
              name
              slug
            }
          }
        }
        ... on ContentfulNavbar {
          id
          __typename
          logo {
            fluid {
              src
            }
          }
          navigationItems {
            items {
              id
              url
              label
              type
              columnNumber
              dropdownItems {
                id
                url
                label
                description
                text {
                  raw
                }
                image {
                  fluid {
                    src
                  }
                }
              }
            }
          }
          companyInformation {
            contactEmail
            copyrights {
              raw
            }
            socialMedia {
              alt
              id
              url
              icon {
                fluid {
                  src
                }
              }
            }
          }
        }
        ... on ContentfulFooter {
          id
          __typename
          logo {
            fluid {
              src
            }
          }
          copyrights {
            raw
          }
          contactEmail
          termsAndConditions {
            file {
              url
            }
          }
          social {
            url
            icon {
              fluid {
                src
              }
            }
            alt
          }
          navigation {
            id
            url
            label
          }
        }
        ... on ContentfulContact {
          id
          __typename
          columnLeft {
            description {
              raw
            }
            title
            successMessage {
              raw
            }
          }
          columnRight {
            description {
              raw
            }
            description2 {
              raw
            }
            title
            phone
            email
          }
        }
        ... on ContentfulIdentifications {
          id
          __typename
          spaceBottom
          spaceTop
          identifications {
            id
            title
            image {
              fluid {
                src
              }
            }
          }
          description {
            raw
          }
          backgroundColor
          textColor
        }
        ... on ContentfulSpacer {
          id
          __typename
          size
          backgroundColor
        }
      }
    }
  }
`;
